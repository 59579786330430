.file-upload-container .p-button.p-component.p-fileupload-choose.p-fileupload-choose-selected {
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: rgba(234, 240, 245, 0.4) !important;
    color: black;
}


.file-upload-container .p-button.p-component.p-fileupload-choose {
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: rgba(234, 240, 245, 0.4) !important;
    color: black;
}